.App {
  text-align: center;
}
/*ALL THROUGHOUT APPLICABLE*/
html { 
  font-size:100%;
  scroll-behavior: smooth; 
  height: 100%;
} 

body {
  background: #1b1b1b;
  overflow-x: hidden;
}

::selection {
  background: #d400ff;
  color:#fff;
}

/* width */
::-webkit-scrollbar {
  width: 1vmax;
  
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px black; 
  border-radius: 0.25vmax;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #d400ff; 
  border-radius: 0.25vmax;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

.preloader {
  background: #fff;
  color: #fff;
  height: 100vh;
  transition: 1s;
  font-family: 'Major Mono Display', monospace;
}

.first-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* NAVIGATIOON BAR */
.navbar ul, .navbar li, .navbar a {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

.navbar {
  font-family: 'Major Mono Display', monospace;
  font-size: 0.9vmax;
  width: 100%;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #1b1b1bd0;
  backdrop-filter: blur(10px);
}

.navbar > a.mobile-btn { display: none;}

ul#nav {
  min-height: 48px;
  width: auto;

  text-align: center;
}

ul#nav li {
  position: relative;
  list-style: none;
  height: 48px;
  display: inline-block;
}

ul#nav li a {
  display: inline-block;
  padding: 8px 13px;
  line-height: 30px;
  text-decoration: none;
  text-align: left;
  color: #fff;

  transition: color .2s ease-in-out;
}

ul#nav li a:active { background-color: transparent !important; }


/*LANDING PAGE*/
header {
  position: relative;
  height:100vh;
  min-height: 500px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.8);
}

header::before{
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

header .landing-text h1 {
  font-family: 'Major Mono Display', monospace;
  font-size: 6vmax;
  color:#fff;
  font-weight: 600;
  /* text-align: left; */
  display: inline-block;
  padding: 25vh 10vw 0;
  letter-spacing: 0px;
  /* line-height: 1.1;
  -webkit-text-fill-color: transparent; 
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white; */
}

header .landing-text h3 {
  color: #fff;
  font-family: 'Major Mono Display', sans-serif;
}

hr {
  width: 1000px;
  border-color: #d400ff;
}

span {
  color: #fff;
  font-family: 'Major Mono Display', monospace;
  letter-spacing: -2px;
  font-size: 3vmax;
}

header .scroll-btn{
  top: 90vh;
  position: absolute;
}

@-webkit-keyframes ani-mouse {
  0% {
  opacity: 1;
  top: 29%;
  }
  15% {
  opacity: 1;
  top: 50%;
  }
  50% {
  opacity: 0;
  top: 50%;
  }
  100% {
  opacity: 0;
  top: 29%;
  }
}
@-moz-keyframes ani-mouse {
  0% {
  opacity: 1;
  top: 29%;
  }
  15% {
  opacity: 1;
  top: 50%;
  }
  50% {
  opacity: 0;
  top: 50%;
  }
  100% {
  opacity: 0;
  top: 29%;
  }
}
@keyframes ani-mouse {
  0% {
  opacity: 1;
  top: 29%;
  }
  15% {
  opacity: 1;
  top: 50%;
  }
  50% {
  opacity: 0;
  top: 50%;
  }
  100% {
  opacity: 0;
  top: 29%;
  }
}
 .scroll-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}
.scroll-btn > * {
  display: inline-block;
  line-height: 18px;
  font-size: 13px;
  font-weight: normal;
  color: #7f8c8d;
  color: #ffffff;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 2px;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
  color: #ffffff;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.scroll-btn .mouse {
  position: relative;
  display: block;
  width: 35px;
  height: 55px;
  margin: 0 auto 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid white;
  border-radius: 23px;
}
.scroll-btn .mouse > * {
  position: absolute;
  display: block;
  top: 29%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  background: white;
  border-radius: 50%;
  -webkit-animation: ani-mouse 2.5s linear infinite;
  -moz-animation: ani-mouse 2.5s linear infinite;
  animation: ani-mouse 2.5s linear infinite;
}

.myVideo {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.4;
}

/*ABOUT ME SECTION*/
#about {
  font-family: 'DM Sans', monospace;
  background: #fff;
  height: 100%;
  position:relative;
}

#about h1 {
  font-family: 'Major Mono Display', monospace;
  padding-top: 40px;
  font-size: 3vmax;
  margin-top: 0;
}

#about p {
  text-align: left;
  font-size: 2vmax;
}

#about .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;;
}

#about .column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#about .double-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  padding-left: 40px;
  align-items: center;
}

#about .profilepic {
  border-radius: 20px;
  max-width: 100%;
  width: 320px;
  margin: 40px;
}


.button-p {
  padding: 0 4vw;
  font-size: 1.75vmax;
}

.button-p1 {
  padding-bottom: 5vh;
  font-size: 1.75vw;
  align-items: center;
}

.button {
  text-decoration: none;
  background-color: black !important;
  border-radius: 5px;
  box-sizing:content-box;
  padding: 20px;
  float:inline-start;
}

.button{
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
}

.button:hover{
background: #000;
color: #fff;
box-shadow: 0 0 .5px #d400ff,
          0 0 2.5px #d400ff,
          0 0 5px #d400ff,
          0 0 20px #d400ff;
-webkit-box-reflect:below 1px linear-gradient(transition, #0005)            
}

.button1 {
  text-decoration: none;
  background-color: white !important;
  border: 3px solid black;
  border-color: #000;
  border-radius: 5px;
  box-sizing:content-box;
  padding: 20px;
  float:inline-start;
}

.button1{
  color: #000;
  text-decoration: none;
  transition: 0.3s;
}

.button1:hover{
color: #000;
box-shadow: 0 0 .5px #d400ff,
          0 0 2.5px #d400ff,
          0 0 5px #d400ff,
          0 0 20px #d400ff;
-webkit-box-reflect:below 1px linear-gradient(transition, #0005)            
}

/* EXPERIENCE SECTION */

#experience {
  font-family: 'DM Sans', monospace;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  height: 100%;
  position:relative;
  font-size: 2vmax !important;
  padding: 0 3rem 1vmax;
  font-weight: 500;
}

#experience h1 {
  font-family: 'Major Mono Display', monospace;
  padding-top: 40px;
  margin-top: 10px;
  font-size: 3vmax;
}

#experience img {
  display:flexbox;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  padding-left: 0px;
  margin-top: 2vh;
  max-width: 8vmax;
  border-radius: 15px;
  position: relative;
  top: 3vh;
}

#experience span {
  font-family: 'DM Sans', sans-serif;
  font-size: 2.5vmax;
}

#experience .icon-img {
  display: inline-block;
  position: relative;
  max-height: 3vmax;
  top: 0.75vmax;
  border-radius: 0;
}

#experience .list {
  text-align: left;
  padding-left: 25vw;
}


/*RESUME SECTION*/

#resume {
  font-family: 'DM Sans', monospace;
  background: #ffffff;
  color: #000;
  position:relative;
  font-size: 2vmax !important;
  padding: 0 2vmax 1vmax;
  font-weight: 500;
}

#resume h1 {
  font-family: 'Major Mono Display', monospace;
  padding-top: 40px;
  margin-top: 10px;
  font-size: 3vmax;
}

#resume img {
  display:flexbox;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  padding-left: 0px;
  margin-top: 2vh;
  max-width: 8vmax;
  border-radius: 15px;
  position: relative;
  top: 3vh;
}

#resume span {
  font-family: 'DM Sans', sans-serif;
  font-size: 2vmax;
}

#resume .icon-img {
  display: inline-block;
  position: relative;
  max-height: 3vmax;
  top: 0.75vmax;
  border-radius: 0;
}

.tech {
  padding: 0 5rem;
}

@media only screen and (max-width: 768px) {
  .tech {
    padding: 0;
  }
}

/*PROJECTS SECTION*/

#projects {
  font-family: 'Montserrat', monospace;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  height: 100%;
  position:relative;
  font-size: 2.5vmax !important;
  padding: 0 2vmax 1vmax;
  font-weight: 600;
}

#projects h1 {
  font-family: 'Major Mono Display', monospace;
  padding-top: 5vh;
  font-size: 3vmax;
  margin-top: 0.5rem;
}

#projects .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;;
}

#projects .double-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  align-items: center;
}

#projects .pimg {
  width: 40vw;
  border-radius: 1vmax;
  margin: 5vh -2vw;
  transition: .5s ease;
  backface-visibility: hidden;
}

#projects .container {
  position: relative;
}

#projects .middle {
  transition: 0.8s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

#projects .container:hover .pimg {
  opacity: 0.2;
}

#projects .container:hover .middle {
  opacity: 1;
}

#projects .text {
  font-family: 'DM Sans', sans-serif;
  font-size: 2.25vmax;
  text-decoration: none;
  color: #fff;
}

.zoom {
  transition: transform 0.7s;
}

.zoom:hover {
  -ms-transform: scale(1.15); /* IE 9 */
  -webkit-transform: scale(1.15); /* Safari 3-8 */
  transform: scale(1.15); 
}

/*CONTACT SECTION*/

#contact {
  background: #fff;
  height: 100%;
  position:relative;
  font-size: 2.5vmax !important;
  /* padding: 0 2vmax 1vmax; */
  font-weight: 500;
}

#contact h1 {
  font-family: 'Major Mono Display', monospace;
  padding-top: 50px;
  font-size: 3vmax;
  margin-top: 0.5rem;
}

#contact p, form{
  font-family: 'DM Sans', sans-serif;
  font-size: 2.5vmax;
  text-align: left;
  padding-left: 4vw;
}


input, textarea {
  width:45vw;
  padding: 2vh 2vw;
  border: #000 solid 0.2vmax;
  border-radius: 0.8vmax;
  background-color: #000;
  color: #d400ff;
  font-family: 'DM Sans', sans-serif;
  font-size: 1.5vmax;
}

h2{
  font-family: 'DM Sans', sans-serif;
  font-size: 1.5vmax;
  padding: 1vmax;
  margin-bottom: 0;
}

.cont-info {
  font-size: 2.5vmax;
  text-align: right;
  line-height: 4vmax;
  position: relative;
  right: 2vw;
}

a {
  text-decoration: none;
  color: #d400ff;
}

#contact .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;;
}

#contact .column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

#contact button {
  background-color: #000;
  border: none;
  color: #d400ff;
  padding: 2vh 2vw;
  text-decoration: none;
  margin: 4px 0;
  cursor: pointer;
  font-family: 'DM Sans', sans-serif;
  font-size: 1.5vmax;
  border-radius: 0.8vmax;
  width: 100%;
  max-width: 25%;
}

#contact button:hover {
  box-shadow: 0 0 .5px #d400ff,
          0 0 2.5px #d400ff,
          0 0 5px #d400ff,
          0 0 20px #d400ff;
-webkit-box-reflect:below 1px linear-gradient(transition, #0005)
}

#contact .socials {
  font-size: 4vmax;
  text-align: right;
  position: relative;
  left: 15vw;
  justify-content: space-between;
  display: flex;
  width: 100%;
  max-width: 60%;
  cursor: pointer;
}

#contact .fab {
  color: #000 ;
}

#contact .socials .fa-facebook-square:hover {
  color: #4267B2;
  transition: transform 0.5s;
  transform:scale(1.2) ;
}

#contact .fa-twitter-square:hover {
  color: #1DA1F2;
  transition: transform 0.5s;
  transform:scale(1.2) ;
}

#contact .fa-instagram:hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
-webkit-background-clip: text;
/* Also define standard property for compatibility */
background-clip: text;
-webkit-text-fill-color: transparent;
transition: transform 0.5s;
transform:scale(1.1) ;
}

#contact .fa-linkedin:hover {
  color: #0072b1;
  transition: transform 0.5s;
  transform:scale(1.2) ;
}

#contact .fa-github:hover {
  transition: transform 0.5s;
  transform:scale(1.2) ;
}

#contact .footer{
  background: #000;
  color: #ffffff;
}

/* MEDIA QUERIES AND RESPONSIVENESS CSS*/
@media screen and (max-width: 1060px) {
  #primary { width:67%; }
  #secondary { width:30%; margin-left:3%;}  
  #about .profilepic {
    display: none;
  }

  #about .column {
    display: none;
  }

  #about .double-column {
    padding: 0px 40px;
  }

  #about p {
    text-align: left;
    font-size: 2.5vmax !important;
  }
  
}

@media screen and (max-width: 768px) {
  .navbar {
    display: none;
  }

  .loader-svg {
    width: 375px;
  }

  #primary { width:100%; }
  #secondary { width:100%; margin:0; border:none; }
  #resume .triple-column {
    display: none; 
  }

  #projects .pimg{
    width: 85vw;
  }

  #contact .cont-info {
    text-align: center;
  }

  input, textarea {
    width: 85vw;;
  }

  #about .skill-btns {
    flex-direction: column;
  }

  #about .button-p1 {
    padding-bottom: 0;
  }

  .scroll-btn {
    display: none;
  }

  input, textarea {
    font-size: 2vmax;
  }

  #contact button {
    font-size: 2vmax;
  }

  #contact .socials {
    font-size: 4vmax;
    justify-content: space-between;
    width: 100%;
    max-width: 60%;
    position: relative;
    left: 19vw !important;
  }

  #experience .list {
    padding-left: 5vw;
  }

  header .landing-text h1 {
    padding: 25vh 0 0;
    font-size: 5vmax;
  }
}

@media (min-width: 640px) { body {font-size:1rem;} } 
@media (min-width:960px) { body {font-size:1.2vmax;} } 
@media (min-width:1100px) { body {font-size:1.5rem;} } 

/* For mobile phones: */
[class*="col-"] {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}